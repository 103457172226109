import React from "react";
import { navigate } from "gatsby-plugin-intl";
import { graphql } from "gatsby";
import LayoutExamples from "../../layouts/LayoutExamples";
import { Headline1Sans } from "../../system42";
import * as styles from "./styles.module.css";
import { ExampleList } from "../../components/ExampleList";
import { accessibleOnClick } from "@system42/core";

export default function ExampleTaskType(props) {
  const { data } = props;

  const examples = data.allStrapiExample.edges.map((edge) => edge.node);
  const countExamples = examples.length;

  const title = `${countExamples} User Testing Examples for ${data.strapiExampleTaskType.title}`;

  function handleClickAllExamples() {
    navigate(`/user-testing-template-library/`);
  }

  return (
    <LayoutExamples
      activeTaskTypeId={data.strapiExampleTaskType.id}
      title={title}
    >
      <button
        className={styles.buttonAllExamples}
        {...accessibleOnClick(handleClickAllExamples)}
      >
        All examples
      </button>
      <Headline1Sans customTag={"h2"} className={styles.title}>
        {data.strapiExampleTaskType.title}
      </Headline1Sans>

      <ExampleList
        context={`library_task-types_${data.strapiExampleTaskType.url}`}
        examples={examples}
      />
    </LayoutExamples>
  );
}

export const query = graphql`
  query exampleTaskType($strapiId: String!, $taskTypeUrl: String!) {
    strapiExampleTaskType(id: { eq: $strapiId }) {
      url
      id
      title
    }
    allStrapiExample(
      filter: {
        online: { eq: true }
        taskTypes: { elemMatch: { url: { eq: $taskTypeUrl } } }
      }
    ) {
      edges {
        node {
          id
          url
          title
          type
          device
          companyName
          cardTitleWhite
          taskTypes {
            title
            url
          }
          categories {
            title
            url
          }
          cardColor
          logo {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`;
